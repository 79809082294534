<template>
    <div>
        <h4>Register</h4>
        <form>
            <label for="login">Login</label>
            <div>
                <input id="login" type="text" v-model="login" required autofocus>
            </div>
            <label for="password">Password</label>
            <div>
                <input id="password" type="password" v-model="password" required>
            </div>
            <label for="password-confirm">Confirm Password</label>
            <div>
                <input id="password-confirm" type="password" v-model="password_confirmation" required>
            </div>
            <label for="password-confirm">Is this an administrator account?</label>
            <div>
                <select v-model="is_admin">
                    <option value=true>Yes</option>
                    <option value=false>No</option>
                </select>
            </div>

            <div>
                <button type="submit" @click="handleSubmit">
                    Register
                </button>
            </div>
        </form>
    </div>
</template>


<script>

    const API_URL_REGISTER = process.env.VUE_APP_BASE_API_URL + "/register";
    const API_URL_REGISTER_ADMIN = process.env.VUE_APP_BASE_API_URL + "/register-admin";

    export default {
        props : ["nextUrl"],
        data(){
            return {
                name : "",
                login: "",
                email : "",
                password : "",
                password_confirmation : "",
                is_admin : false
            }
        },
        methods : {
            handleSubmit(e) {
                e.preventDefault()

                if (this.password === this.password_confirmation && this.password.length > 0)
                {
                    let url = API_URL_REGISTER;
                    if(this.is_admin != null && this.is_admin == "true") url = API_URL_REGISTER_ADMIN
                    this.$http.post(url, {
                        login: this.login,
                        password: this.password,
                        is_admin: this.is_admin
                    })
                    .then(response => {
                        localStorage.setItem('user',JSON.stringify(response.data.user))
                        localStorage.setItem('jwt',response.data.token)

                        if (localStorage.getItem('jwt') != null) {
                            this.$emit('loggedIn')
                            if (this.$route.params.nextUrl != null) {
                                this.$router.push(this.$route.params.nextUrl);
                            } else {
                                this.$router.push('/');
                            }
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line no-console
                        console.error(error);
                    });
                } else {
                    this.password = ""
                    this.passwordConfirm = ""

                    return alert("Passwords do not match")
                }
            }
        }
    }
</script>